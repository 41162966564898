.dra-perfil::before {
    position: absolute;
    top: -0.75rem;
    left: -2%;
    width: 100%;
    height: 100%;
    border: 1.5px solid #37748E;
    content: '';
    border-radius: 15px;
  }

  .dra-perfil::after {
    position: absolute;
    top: 0.75rem;
    left: 2%;
    width: 100%;
    height: 100%;
    border: 1.5px solid #37748E;
    content: '';
    border-radius: 15px;
  }